import { useNavigate } from 'react-router-dom';
import styles from './LandingPage.module.scss';

export const LandingPage = () => {
  const navigate = useNavigate();

  const handleAccessApp = () => {
    navigate('/dashboard'); // Redireciona para a rota principal da aplicação
  };

  const professionals = [
    {
      name: 'Lucas Feitosa',
      role: 'Desenvolvedor',
      contact: 'lucasdf10@gmail.com',
      instagram: '@lcsfeitosa',
      image:
        'https://media.licdn.com/dms/image/v2/C4E03AQFmKoppvMTyRQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1643760923070?e=1741824000&v=beta&t=EsGoCO4SbZ0dlG4FmShoMwgje4NdIDByqPd2vThb-70',
    },
    {
      name: 'Isabela Burdino',
      role: 'Nutricionista',
      contact: 'mariana@example.com',
      instagram: '@belburdino',
      image:
        'https://media.licdn.com/dms/image/v2/C4E03AQFhHe9xCIR5eg/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1594346165929?e=1741824000&v=beta&t=Q_h_TsvZPfSmVkfxbUe5EGPluw6RxVjG9YQEFyCL2U0',
    },
    {
      name: 'Vitor Macedo',
      role: 'Personal Trainer',
      contact: 'vitor@example.com',
      instagram: '@vitor.personal',
      image: 'https://via.placeholder.com/150',
    },
  ];

  return (
    <div className={styles['landing-page']}>
      <header className={styles['header']}>
        <h1>Transforme seu Treinamento com o Fit Training Challenge</h1>
        <p>
          O Fit Training Challenge é a solução ideal para quem busca
          organização, motivação e resultados no mundo dos treinos. Seja em
          grupo ou individualmente, você terá o controle completo das suas metas
          e conquistas.
        </p>
        <button className={styles['access-button']} onClick={handleAccessApp}>
          Acessar a Aplicação
        </button>
      </header>

      <main className={styles['content']}>
        <section className={styles['section']}>
          <h2>Funcionalidades Principais</h2>
          <ul>
            <li>
              <strong>Gestão de Grupos:</strong> Crie e organize grupos
              personalizados com facilidade.
            </li>
            <li>
              <strong>Acompanhamento de Desempenho:</strong> Veja a pontuação e
              evolução de cada integrante em tempo real.
            </li>
            <li>
              <strong>Registro de Atividades:</strong> Registre treinos como
              musculação, corrida, crossfit e mais com apenas alguns cliques.
            </li>
            <li>
              <strong>Segurança e Privacidade:</strong> Cada participante tem
              sua senha pessoal para acessar e gerenciar suas informações.
            </li>
          </ul>
        </section>

        <section className={styles['section']}>
          <h2>Por que Escolher o Fit Training Challenge?</h2>
          <p>
            Com o Fit Training Challenge, você tem à disposição uma ferramenta
            intuitiva que não apenas organiza seus treinos, mas também motiva
            você e sua equipe a alcançarem objetivos maiores. Desafie seus
            amigos, registre atividades e acompanhe sua evolução com facilidade.
          </p>
        </section>

        <section className={styles['section']}>
          <h2>Conheça Nossos Profissionais</h2>
          <div className={styles['professionals']}>
            {professionals.map((pro) => (
              <div key={pro.name} className={styles['professional-card']}>
                <img
                  src={pro.image}
                  alt={`${pro.name} - ${pro.role}`}
                  className={styles['professional-image']}
                />
                <h3>{pro.name}</h3>
                <p>
                  <strong>Função:</strong> {pro.role}
                </p>
                <p>
                  <strong>Contato:</strong> {pro.contact}
                </p>
                <p>
                  <strong>Instagram:</strong> {pro.instagram}
                </p>
              </div>
            ))}
          </div>
        </section>

        <section className={styles['section']}>
          <h2>Como Funciona?</h2>
          <p>
            O Fit Training Challenge é simples e eficiente: Crie seu grupo,
            adicione participantes, registre atividades e acompanhe o
            desempenho. Tudo isso com segurança e praticidade para você focar
            nos resultados.
          </p>
        </section>

        <section className={styles['section']}>
          <h2>Benefícios para Você e Sua Equipe</h2>
          <ul>
            <li>
              <strong>Motivação Extra:</strong> Transforme o treino em um
              desafio saudável.
            </li>
            <li>
              <strong>Resultados Claros:</strong> Acompanhe o progresso de
              maneira detalhada.
            </li>
            <li>
              <strong>Fácil de Usar:</strong> Interface intuitiva para uma
              experiência sem complicações.
            </li>
            <li>
              <strong>Colaboração:</strong> Fortaleça a união da equipe
              trabalhando juntos por objetivos em comum.
            </li>
          </ul>
        </section>
      </main>
    </div>
  );
};

export default LandingPage;
