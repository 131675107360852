import React, { createContext, ReactNode, useState } from 'react';

// Interface para o contexto
interface Group {
  id: string;
  name: string;
  totalParticipants?: number;
  totalScore?: number;
}

interface GroupContextType {
  group: Group | null;
  setGroup: React.Dispatch<React.SetStateAction<Group | null>>;
}

// Valor padrão para o contexto
const defaultGroupContext: GroupContextType = {
  group: null,
  setGroup: () => {},
};

// Criação do contexto
export const GroupContext =
  createContext<GroupContextType>(defaultGroupContext);

// Propriedades do Provider
interface GroupProviderProps {
  children: ReactNode;
}

// Componente Provider
export const GroupProvider: React.FC<GroupProviderProps> = ({ children }) => {
  const [group, setGroup] = useState<Group | null>(null);

  return (
    <GroupContext.Provider value={{ group, setGroup }}>
      {children}
    </GroupContext.Provider>
  );
};
