import React, { useState } from 'react';
import styles from './AddNewGroupModal.module.scss';

interface AddNewGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddGroup: (name: string, password: string) => void;
  isPerson?: boolean;
}

const AddNewGroupModal: React.FC<AddNewGroupModalProps> = ({
  isOpen,
  onClose,
  onAddGroup,
  isPerson = false,
}) => {
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');

  const handleAddGroup = () => {
    if (name.trim() && password.trim()) {
      onAddGroup(name, password);
      setName(''); // Limpa o campo Nome
      setPassword(''); // Limpa o campo Senha
      onClose(); // Fecha o modal
    } else {
      alert('Please fill out both fields.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modal}>
      <div className={styles['modal-content']}>
        <h2>Adicionar Novo Grupo</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleAddGroup();
          }}
          className={styles['form']}
        >
          <div className={styles['form-group']}>
            <label htmlFor="group-name">
              {isPerson ? 'Nome da Pessoa' : 'Nome do Grupo'}
            </label>
            <input
              type="text"
              id="group-name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={isPerson ? 'Nome da Pessoa' : 'Nome do Grupo'}
              required
            />
          </div>
          <div className={styles['form-group']}>
            <label htmlFor="group-password">Senha</label>
            <input
              type="password"
              id="group-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Digite uma senha"
              required
            />
          </div>
          <div className={styles['modal-actions']}>
            <button type="submit" className={styles['confirm-button']}>
              Adicionar
            </button>
            <button
              type="button"
              className={styles['cancel-button']}
              onClick={onClose}
            >
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddNewGroupModal;
