import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddNewGroupModal from '../../components/AddNewGroupModal/AddNewGroupModal';
import ModalPassword from '../../components/ModalPassword/ModalPassword';
import { useGroup } from '../../hooks/useGroup';
import { createGroup, getAllGroups } from '../../services/groupService';
import styles from './Dashboard.module.scss';

interface Group {
  id: string;
  name: string;
  total_participants: number;
  total_score: string;
  password?: string; // Só usado no momento da criação
}

export const Dashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenPassword, setIsModalOpenPassword] = useState(false);
  const [groupList, setGroupList] = useState<Group[]>([]);
  const navigate = useNavigate();
  const [groupId, setGroupId] = useState('');
  const { group, setGroup } = useGroup();

  // Carrega os grupos do backend
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const groups = await getAllGroups();
        setGroupList(groups);
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    };

    fetchGroups();
  }, []);

  const handleAddGroup = async (name: string, password: string) => {
    try {
      const newGroup = await createGroup(name, password);
      setGroupList([...groupList, newGroup]);
      setIsModalOpen(false); // Fecha o modal após criar o grupo
    } catch (error) {
      console.error('Error creating group:', error);
      alert('Failed to create group. Please try again.');
    }
  };

  const handleViewDetails = (id: string) => {
    navigate(`/group/${id}`);
  };

  const handleGoBack = () => {
    navigate(-1); // Navega para a página anterior
  };

  return (
    <div className={styles.dashboard}>
      <h1>Todos os Grupos</h1>
      <div className={styles['button-container']}>
        <button
          className={styles['add-group-button']}
          onClick={() => setIsModalOpen(true)}
        >
          Adicionar Novo Grupo
        </button>

        <button
          className={styles['add-group-button']}
          onClick={handleGoBack}
          style={{ marginLeft: '16px' }}
        >
          Voltar
        </button>
      </div>
      <table className={styles['group-table']}>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Pessoas</th>
            <th>Pontos Totais</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {groupList
            .sort(
              (groupA, groupB) =>
                Number(groupB.total_score) - Number(groupA.total_score),
            )
            .map((group) => (
              <tr key={group.id}>
                <td>{group.name}</td>
                <td>{group?.total_participants ?? 0}</td>
                <td>{group?.total_score ?? 0}</td>
                <td>
                  <button
                    className={styles['view-button']}
                    onClick={() => {
                      setIsModalOpenPassword(true);
                      setGroupId(group.id);
                    }}
                  >
                    Acessar Grupo
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <AddNewGroupModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onAddGroup={handleAddGroup}
      />

      <ModalPassword
        isOpen={isModalOpenPassword}
        onClose={() => setIsModalOpenPassword(false)}
        onConfirm={(group) => {
          handleViewDetails(group?.id + '');
          setGroup({
            id: group.id + '',
            name: group.name,
          });
        }}
        isGroup={true}
        id={groupId}
      />
    </div>
  );
};
