import { Route, Routes } from 'react-router-dom';
import { Dashboard } from '../pages/Dashboard/Dashboard';
import { GroupDetails } from '../pages/GroupDetails/GroupDetails';
import LandingPage from '../pages/LandingPage/LadinngPage';

function AppRoutes() {
  return (
    <Routes>
      {/* Tela de Login */}
      <Route path="/" element={<LandingPage />} />

      {/* Rotas Protegidas */}
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/group/:id" element={<GroupDetails />} />

      {/* Página Não Encontrada */}
      <Route path="*" element={<div>404 - Page Not Found</div>} />
    </Routes>
  );
}

export default AppRoutes;
