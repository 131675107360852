import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AddNewGroupModal from '../../components/AddNewGroupModal/AddNewGroupModal';
import ModalScore from '../../components/ModalScore/ModalScore';
import { useGroup } from '../../hooks/useGroup';
import {
  addParticipant,
  getParticipantsByGroupId,
  updateParticipantScore,
} from '../../services/participantService';
import styles from './GroupDetails.module.scss';

interface Person {
  id: string;
  name: string;
  score: number;
  password?: string;
}

export const GroupDetails = () => {
  const { id: groupId } = useParams<{ id: string }>();
  const [people, setPeople] = useState<Person[]>([]);
  const [isScoreModalOpen, setIsScoreModalOpen] = useState(false); // Controle do ModalScore
  const [isAddPersonModalOpen, setIsAddPersonModalOpen] = useState(false); // Controle do AddNewGroupModal
  const [selectedPerson, setSelectedPerson] = useState<Person | null>(null);
  const navigate = useNavigate();
  const { group, setGroup } = useGroup();

  // Função para carregar participantes do backend
  const fetchParticipants = useCallback(async () => {
    try {
      if (groupId) {
        const participants = await getParticipantsByGroupId(groupId);
        setPeople(participants);
      }
    } catch (error) {
      console.error('Error fetching participants:', error);
    }
  }, [groupId]);

  // Carregar os participantes na montagem do componente
  useEffect(() => {
    fetchParticipants();
  }, [groupId, fetchParticipants]);

  // Abrir ModalScore
  const handleOpenScoreModal = (person: Person) => {
    setSelectedPerson(person);
    setIsScoreModalOpen(true);
  };

  // Fechar ModalScore
  const handleCloseScoreModal = () => {
    setIsScoreModalOpen(false);
    setSelectedPerson(null);
  };

  // Adicionar pontuação
  const handleAddScore = async (score: number, password: string) => {
    if (selectedPerson && groupId) {
      try {
        await updateParticipantScore(selectedPerson.id, score, password);
        fetchParticipants(); // Atualiza a lista após adicionar pontuação
      } catch (error) {
        console.error('Error updating score:', error);
      }
    }
  };

  // Abrir AddNewGroupModal
  const handleOpenAddPersonModal = () => {
    setIsAddPersonModalOpen(true);
  };

  // Fechar AddNewGroupModal
  const handleCloseAddPersonModal = () => {
    setIsAddPersonModalOpen(false);
  };

  // Adicionar novo integrante
  const handleAddPerson = async (name: string, password: string) => {
    if (groupId) {
      try {
        await addParticipant(groupId, name, password);
        fetchParticipants(); // Atualiza a lista após adicionar novo participante
        handleCloseAddPersonModal();
      } catch (error) {
        console.error('Error adding participant:', error);
      }
    }
  };

  const handleGoBack = () => {
    navigate(-1); // Navega para a página anterior
  };

  return (
    <div className={styles['group-details']}>
      <h1 className={styles['title']}>Grupo: {group?.name}</h1>

      {/* Botão para adicionar novo integrante */}
      <button
        className={styles['add-person-button']}
        onClick={handleOpenAddPersonModal}
      >
        Adicionar participante
      </button>

      <button
        className={styles['add-person-button']}
        onClick={handleGoBack}
        style={{ marginLeft: '16px' }}
      >
        Voltar
      </button>

      {/* Tabela de integrantes */}
      <table className={styles['people-table']}>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Pontos</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {people
            .sort((personA, personB) => personB.score - personA.score)
            .map((person) => (
              <tr key={person.id}>
                <td>{person.name}</td>
                <td>{person.score}</td>
                <td>
                  <button
                    className={styles['add-score-button']}
                    onClick={() => handleOpenScoreModal(person)}
                  >
                    Adicionar Pontos
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* Modal para pontuar */}
      <ModalScore
        isOpen={isScoreModalOpen}
        onClose={handleCloseScoreModal}
        onConfirm={handleAddScore}
        userName={selectedPerson?.name || ''}
      />

      {/* Modal para adicionar novo integrante */}
      <AddNewGroupModal
        isPerson={true}
        isOpen={isAddPersonModalOpen}
        onClose={handleCloseAddPersonModal}
        onAddGroup={handleAddPerson} // Reaproveita a lógica de adição
      />
    </div>
  );
};
