import React, { createContext, ReactNode, useState } from 'react';

// Tipagem do contexto
interface AuthContextType {
  user: string | null;
  setUser: React.Dispatch<React.SetStateAction<string | null>>;
}

// Valor inicial do contexto
const defaultAuthContext: AuthContextType = {
  user: null,
  setUser: () => {},
};

// Criação do contexto com valor padrão
export const AuthContext = createContext<AuthContextType>(defaultAuthContext);

// Tipagem para o AuthProvider
interface AuthProviderProps {
  children: ReactNode;
}

// Componente AuthProvider
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<string | null>(null);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};
