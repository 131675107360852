import api from './api';

// Retorna todos os grupos
export const getAllGroups = async () => {
  const response = await api.get('/groups');
  return response.data;
};

// Busca um grupo pelo ID
export const getGroupById = async (id: string) => {
  const response = await api.get(`/groups/${id}`);
  return response.data;
};

// Cria um novo grupo
export const createGroup = async (name: string, password: string) => {
  const response = await api.post('/groups', { name, password });
  return response.data;
};

// Verifica a senha de um grupo específico
export const getGroupByIdAndPassword = async (id: string, password: string) => {
  const response = await api.post(`/groups/${id}/access`, { password });
  return response.data;
};
