import React, { useEffect, useState } from 'react';
import styles from './ModalScore.module.scss';

interface ModalScoreProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (score: number, password: string) => void;
  userName: string; // Nome do usuário da tela anterior
}

const ModalScore: React.FC<ModalScoreProps> = ({
  isOpen,
  onClose,
  onConfirm,
  userName,
}) => {
  const [selectedActivities, setSelectedActivities] = useState<string[]>([]);
  const [password, setPassword] = useState(''); // Estado para armazenar a senha

  const activities = [
    'Aeróbico',
    'Musculação',
    'Crossfit',
    'Funcional',
    'Outros',
  ];

  const toggleActivity = (activity: string) => {
    setSelectedActivities((prev) =>
      prev.includes(activity)
        ? prev.filter((item) => item !== activity)
        : [...prev, activity],
    );
  };

  const handleConfirm = () => {
    onConfirm(selectedActivities.length, password); // Passa a pontuação e a senha
    resetState();
    onClose(); // Fecha o modal
  };

  const resetState = () => {
    setSelectedActivities([]); // Limpa as atividades selecionadas
    setPassword(''); // Limpa o campo de senha
  };

  // Reseta o estado ao abrir ou fechar o modal
  useEffect(() => {
    if (!isOpen) {
      resetState();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={styles.modal}>
      <div className={styles['modal-content']}>
        <h2>Adicionar Pontuação</h2>
        <p>
          Adicionando pontos para: <strong>{userName}</strong>
        </p>
        {/* Exibição do Total de Pontos */}
        <div className={styles['total-points']}>
          Total de Pontos: <strong>{selectedActivities.length}</strong>
        </div>
        {/* Checkboxes */}
        <div className={styles['checkbox-group']}>
          {activities.map((activity) => (
            <label key={activity} className={styles['checkbox-label']}>
              <input
                type="checkbox"
                value={activity}
                checked={selectedActivities.includes(activity)}
                onChange={() => toggleActivity(activity)}
              />
              {activity}
            </label>
          ))}
        </div>

        {/* Campo para senha */}
        <div className={styles['password-group']}>
          <label htmlFor="password" className={styles['password-label']}>
            Senha
          </label>
          <input
            type="password"
            id="password"
            className={styles['password-input']}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        {/* Botões de Ação */}
        <div className={styles['modal-actions']}>
          <button className={styles['confirm-button']} onClick={handleConfirm}>
            Confirmar
          </button>
          <button
            className={styles['cancel-button']}
            onClick={() => {
              resetState();
              onClose();
            }}
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalScore;
