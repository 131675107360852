import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { GroupProvider } from './context/GroupContext';
import './global.scss';
import AppRoutes from './routes/AppRoutes';

function App() {
  return (
    <AuthProvider>
      <GroupProvider>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </GroupProvider>
    </AuthProvider>
  );
}

export default App;
