import api from './api';

// Lista todos os participantes de um grupo específico
export const getParticipantsByGroupId = async (groupId: string) => {
  const response = await api.get(`/participants/group/${groupId}`);
  return response.data;
};

// Adiciona um participante a um grupo
export const addParticipant = async (
  groupId: string,
  name: string,
  password: string,
) => {
  const response = await api.post('/participants', { groupId, name, password });
  return response.data;
};

// Atualiza a pontuação de um participante
export const updateParticipantScore = async (
  participantId: string,
  score: number,
  password: string,
) => {
  const response = await api.put(`/participants/${participantId}/score`, {
    score,
    password,
  });
  return response.data;
};

// Verifica as credenciais de um participante
export const getParticipantByIdAndPassword = async (
  participantId: string,
  password: string,
) => {
  const response = await api.post(`/participants/${participantId}/access`, {
    password,
  });
  return response.data;
};
