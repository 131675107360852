import React, { useEffect, useState } from 'react';

import { getGroupByIdAndPassword } from '../../services/groupService';
import { getParticipantByIdAndPassword } from '../../services/participantService';
import styles from './ModalPassword.module.scss';

interface ModalPasswordProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (data: { id: number; name: string }) => void; // Função de callback para confirmar os dados
  isGroup: boolean; // Indica se estamos lidando com grupo ou participante
  id: string;
}

const ModalPassword: React.FC<ModalPasswordProps> = ({
  isOpen,
  onClose,
  onConfirm,
  isGroup,
  id,
}) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleConfirm = async () => {
    try {
      const data: { id: number; name: string } = isGroup
        ? await getGroupByIdAndPassword(id, password)
        : await getParticipantByIdAndPassword(id, password);

      onConfirm(data);
      resetState();
      onClose();
    } catch (_) {
      setError('Invalid password. Please try again.');
    }
  };

  const resetState = () => {
    setPassword('');
    setError('');
  };

  useEffect(() => {
    if (!isOpen) {
      resetState();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={styles.modal}>
      <div className={styles['modal-content']}>
        <h2>{isGroup ? 'Access Group' : 'Access Participant'}</h2>
        <div className={styles['form-group']}>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
          />
        </div>
        {error && <p className={styles['error-message']}>{error}</p>}
        <div className={styles['modal-actions']}>
          <button className={styles['confirm-button']} onClick={handleConfirm}>
            Confirm
          </button>
          <button
            className={styles['cancel-button']}
            onClick={() => {
              resetState();
              onClose();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalPassword;
