import axios from 'axios';

// Configuração da instância do axios
const api = axios.create({
  baseURL: 'https://lcsfeitosa.com/api-fit-training', // URL base do backend
  timeout: 10000, // Timeout de 10 segundos
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptores podem ser adicionados aqui, se necessário
api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('API Error:', error.response || error.message);
    return Promise.reject(error);
  },
);

export default api;
